<template>
  <div class="flex flex-col gap-y-10">
    <Head>
      <Title>{{ $t("Radio") }}</Title>
    </Head>
    <client-only>
      <template #fallback>
        <SkeletonsHeroBanner></SkeletonsHeroBanner>
      </template>

      <PageBuilder
        v-if="!loading"
        :layout="pageLayout"
        :kind="kind"
      ></PageBuilder>
    </client-only>
  </div>
</template>

<script setup>
const kind = ref("radio");
const pageLayout = ref();
const loading = ref(true);

if (process.client) {
  await useFmaSdk().logPageNav(usePageId().ADULT__RADIO);
}

onMounted(async () => {
  const pageLayoutRes = await useGetMethod("/api/biz/config/v1/carousels", {
    page: kind.value,
  });

  pageLayout.value = pageLayoutRes;
  await useFmaSdk().loaded();
  loading.value = false;
});

onBeforeUnmount(() => {
  useFmaSdk().terminate();
});
</script>

<script>
export default { name: "RadioLandingPage" };
</script>

<style lang="scss" scoped></style>
